import './index.scss';
import AnimatedLetters from "../AnimatedLetters";
import React, { useEffect, useRef, useState } from "react";
import Loader from "react-loaders";
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const formRef = useRef()

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('service_h0te01n', 'template_w6k7rnt', formRef.current, 'WvHQuPOkIIFGM6hWI')
            .then(
                (response) => {
                    console.log('Email sent successfully:', response);
                    alert('Email successfully sent!');
                },
                (error) => {
                    console.error('Failed to send the Email:', error);
                    alert('Failed to send the Email, please try again');
                }
            );

    }

    return (
        <div>
            <div className="container contact-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters letterClass={letterClass}
                                         strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']} idx={15} />
                    </h1>
                    <p>I'm open to exciting opportunities and collaborations. If you have a project or position that aligns
                        with my skills and passion for web development, I would love to connect and discuss how I can
                        contribute to its success. Feel free to reach out via email at <span>kevintony477@gmail.com</span>, or you can
                        use the text box on the right to send me an email, and I will get back to you. I'm enthusiastic
                        about the possibility of collaborating on innovative and impactful endeavors!</p>
                </div>
                <div className="contact-form">
                    <form ref={formRef} onSubmit={sendEmail}>
                        <ul>
                            <li className="half">
                                <input placeholder="Name" type="text" name="name" required />
                            </li>
                            <li className="half">
                                <input placeholder="Email" type="email" name="email" required/>
                            </li>
                            <li>
                                <input placeholder="Subject" type="text" name="subject" required/>
                            </li>
                            <li>
                                <textarea placeholder="Message" name="message" required></textarea>
                            </li>
                            <li>
                                <input type="submit" className="flat-button" value="SEND" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            <Loader type='ball-pulse' active />
        </div>
    );
};

export default Contact;
