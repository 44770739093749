import './index.scss';
import AnimatedLetters from "../AnimatedLetters";
import React, {useEffect, useState} from "react";
import Loader from "react-loaders";
import kings from "../../assets/images/kings.png"
import player from "../../assets/images/Splayer.png"
import playlist from "../../assets/images/Splaylist.png"
import {Link} from "react-router-dom";

const Project = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);


    return (
        <div>
            <div className="container project-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['P', 'r', 'o', 'j', 'e', 'c', 't']}
                                         idx={15}/>
                    </h1>
                    <p>These are the project that I had done before.</p>
                    <ul>
                        <a href='https://kingsvegetarianfood.com/' target="_blank" rel="noreferrer">
                            <li>King's Vegetarian Food Kelowna</li>
                        </a>
                        <li>Spotify Playlist</li>
                    </ul>
                </div>
                <div className='project'>
                    <Link className='kings' to='https://kingsvegetarianfood.com/' target="_blank" rel="noreferrer">
                        <img src={kings} alt="kins" className='kingsp'/>
                    </Link>
                    <Link className='playlist' to="http://kuanyichiang.com/creatplaylist/" target="_blank" rel="noreferrer">
                        <img src={playlist} alt="kins" className='playlistp'/>
                    </Link>

                </div>
            </div>
            <Loader type='ball-pulse' active/>
        </div>

    );
};

export default Project;
