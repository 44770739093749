import './index.scss';
import AnimatedLetters from "../AnimatedLetters";
import React, {useEffect, useState} from "react";
import Loader from "react-loaders";
import LogoK from "../../assets/images/logo-k.png";

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div>
            <div className="container about-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']}
                                         idx={15}/>
                    </h1>
                    <p> Hi there! I'm Kuan-Yi Chiang, also known as Kevin, a forward-thinking front-end developer
                        exploring
                        opportunities in established IT companies. </p>

                    <p>Confident and detail-oriented, I tackle design challenges with a problem-solving mindset. Armed
                        with
                        a degree in Computer Science and specialized training in Web Site Design and Development, my
                        expertise
                        extends to projects like King's Vegetarian Food Kelowna and a Codecademy Front-End Engineer
                        Certificate.</p>

                    <p>Beyond coding, I'm a dedicated family person, and sports enthusiast. Excited about contributing
                        my skills
                        to innovative projects, I invite you to explore my portfolio for a closer look. Let's connect
                        and explore
                        the possibilities. References available upon request.

                    </p>
                </div>
                <div className='Logo-container'>
                    <img src={LogoK} alt='logo'/>
                </div>
            </div>
            <Loader type='ball-pulse'/>
        </div>
    );
};

export default About;
