import './index.scss'
import React from 'react';
import {Link, NavLink} from "react-router-dom";
import LogoK from '../../assets/images/logo-kw.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faHome, faUser, faList, faBookmark} from "@fortawesome/free-solid-svg-icons";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";

const Sidebar = () => {
    return (
         <div className='nav-bar'>
             <Link className='logo' to="/">
                 <img src={LogoK} alt='logo'/>
             </Link>
             <nav>
                 <NavLink exact='true' activeclassname='active' to='/'>
                     <FontAwesomeIcon icon={faHome} color='#fff'/>
                 </NavLink>
                 <NavLink exact='true' activeclassname='active' className="about-link" to='/about'>
                     <FontAwesomeIcon icon={faUser} color='#fff'/>
                 </NavLink>
                 <NavLink exact='true' activeclassname='active' className="skill-link" to='/skill'>
                     <FontAwesomeIcon icon={faList} color='#fff'/>
                 </NavLink>
                 <NavLink exact='true' activeclassname='active' className="project-link" to='/project'>
                     <FontAwesomeIcon icon={faBookmark} color='#fff'/>
                 </NavLink>
                 <NavLink exact='true' activeclassname='active' className="contact-link" to='/contact'>
                     <FontAwesomeIcon icon={faEnvelope} color='#fff'/>
                 </NavLink>
             </nav>
             <ul>
                 <li>
                     <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/in/kuan-yi-chiang-30a932270/'>
                         <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e"/>
                     </a>
                 </li>
             </ul>
         </div>
    );
};

export default Sidebar;