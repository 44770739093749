import {Link} from "react-router-dom";
import './index.scss';
import AnimatedLetters from "../AnimatedLetters";
import React, {useEffect, useState} from "react";
import LogoK from '../../assets/images/logo-k.png'
import Loader from "react-loaders";


const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = [' ','K','e','v','i','n']
    const jobArray= ['W','e','b',' ','D', 'e','v','e','l','o','p','e','r']

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 4000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span className={letterClass}>H</span>
                        <span className={`${letterClass} _12`}>i,</span>
                        <br/>
                        <span className={`${letterClass} _13`}>I</span>
                        <span className={`${letterClass} _14`}>'m</span>
                        <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>
                        <br/>
                        <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={21}/>
                    </h1>
                    <h2>Front-end Developer</h2>
                    <Link to="/contact" className='flat-button'>CONTACT ME</Link>
                </div>
                <div className='Logo-container'>
                    <img src={LogoK} alt='logo'/>
                </div>
            </div>
            <Loader type='ball-pulse' active/>
        </div>

    );
};

export default Home;