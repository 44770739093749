import './index.scss';
import AnimatedLetters from "../AnimatedLetters";
import React, {useEffect, useState} from "react";
import Loader from "react-loaders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCss3, faGitAlt, faHtml5, faJsSquare, faReact} from "@fortawesome/free-brands-svg-icons";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";

const Skill = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div>
            <div className="container skill-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['S', 'k', 'i', 'l', 'l', 's']} idx={15}/>
                    </h1>
                    <p>Expert in front-end development including technologies like</p>
                    <ul>
                        <li>HTML5</li>
                        <li>CSS3</li>
                        <li>JavaScript</li>
                        <li>Jquery</li>
                        <li>React</li>
                        <li>Bootstrap</li>
                        <li>Typescript</li>
                        <li>WordPress</li>
                        <li>Python</li>
                        <li>SQL</li>
                        <li>Node.js</li>
                    </ul>
                </div>
                <div className="stage-cube-cont">
                    <div className="cubespinner">
                        <div className="face1">
                            <FontAwesomeIcon icon={faDatabase} color="#6F939F"/>
                        </div>
                        <div className="face2">
                            <FontAwesomeIcon icon={faHtml5} color="#F06529"/>
                        </div>
                        <div className="face3">
                            <FontAwesomeIcon icon={faCss3} color="#28A4D9"/>
                        </div>
                        <div className="face4">
                            <FontAwesomeIcon icon={faReact} color="#5ED4F4"/>
                        </div>
                        <div className="face5">
                            <FontAwesomeIcon icon={faJsSquare} color="#EFD81D"/>
                        </div>
                        <div className="face6">
                            <FontAwesomeIcon icon={faGitAlt} color="#EC4D28"/>
                        </div>
                    </div>
                </div>
            </div>
            <Loader type='ball-pulse' active/>
        </div>

    );
};

export default Skill;
