import './App.scss';
import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import About from "./components/About";
import Skill from "./components/Skill";
import Project from "./components/Project";
import Contact from "./components/Contact";

function App() {
  return (
      <>
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/skill" element={<Skill/>}/>
                <Route path="/project" element={<Project/>}/>
                <Route path="/Contact" element={<Contact/>}/>
            </Route>
        </Routes>
      </>
  )
}

export default App;
